import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Input, Modal, Cascader, Select, Space, Row, Col} from "antd";
import shallow from 'zustand/shallow'
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";
import http from "../../../http/http-common";

const EditModal = ({ FetchUrl }) => {
    const [form] = Form.useForm()
    const { Update, Create, Cancel } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const [Item, Loading, ModalVisible, AdditionalData] = useCrudStore(state => [state.Item, state.Loading, state.ModalVisible, state.AdditionalData], shallow)
    const IsEdit = Item.action === 'edit'
    const StockItemSelect = useRef()
    const [Recipes, SetRecipes] = useState([]);

    const Submit = () => {
        form.validateFields()
            .then(values => {
                values.unit_type = values.unit_type_and_item[0]
                values.stock_unit_id = values.unit_type_and_item[1]
                if (IsEdit)
                    Update(Item.key, {stock_unit: values})
                else
                    Create({stock_unit: values})
            })
            .catch(errors => {
                console.log(errors)
            })
    }

    const CategorySelected = (value, option) => {
        http()
            .get(`/api/v1/recipe_categories/${value}/recipes`, {params: {only_final: 1}})
            .then((response) => {
                let { data } = response.data
                SetRecipes(data)
            })
    }

    return (
        <Modal
            visible={ModalVisible}
            title={IsEdit ? "Düzenle" : "Yeni"}
            onOk={Submit}
            onCancel={Cancel}
            width={1000}
            footer={[
                <Button key="back" onClick={Cancel}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={Loading} onClick={Submit}>
                    { IsEdit ? "Güncelle" : "Yeni" }
                </Button>
            ]}
        >
            <Form
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={Item}
                form={form}
            >
                <Form.Item name="consumable_id" label={'Stoklu Ürün'} rules={[{required: true}]}>
                    <Select
                        // filterOption={false}
                        ref={StockItemSelect}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.label.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0
                        }
                        //onSearch={debounceFetcher}
                        showSearch={true}
                        onChange={CategorySelected}
                        options={AdditionalData.recipe_categories}
                    />
                </Form.Item>
                {
                    Recipes.map((recipe) => (
                        <Row gutter={16} key={`space_${recipe.key}`}>
                            <Form.Item key={`fi_1${recipe.key}`} hidden={true} label={'Ürün'} >
                                <Input key={`name_${recipe.key}`} autoComplete="off" value={recipe.key} disabled={true} />
                            </Form.Item>
                            <Col span={8}>
                                <Form.Item key={`fi_1${recipe.key}`} label={'Ürün'} >
                                    <Input key={`name_${recipe.key}`} autoComplete="off" value={recipe.name} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item key={`fi_1${recipe.key}`} name="name" label={'Miktar'} rules={[{required: true}]}>
                                    <Input key={`amount_${recipe.key}`} autoComplete="off" placeholder={'Miktar'} />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item key={`fi_2${recipe.key}`} name="name" label={'Birim'} rules={[{required: true}]}>
                                    <Input key={`unit_${recipe.key}`} autoComplete="off" placeholder={'Birim'} />
                                </Form.Item>
                            </Col>
                        </Row>
                    ))
                }


            </Form>
        </Modal>
    )
}

export default EditModal;