import React, {useState} from "react";
import {Button, Form, Input, Modal, Cascader} from "antd";
import shallow from 'zustand/shallow'
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";

const EditModal = ({ FetchUrl }) => {
    const [form] = Form.useForm()
    const { Update, Create, Cancel } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const [Item, Loading, ModalVisible] = useCrudStore(state => [state.Item, state.Loading, state.ModalVisible], shallow)
    const IsEdit = Item.action === 'edit'

    const Submit = () => {
        form.validateFields()
            .then(values => {
                if (IsEdit)
                    Update(Item.key, {user: values})
                else
                    Create({user: values})
            })
            .catch(errors => {
                console.log(errors)
            })
    }

    return (
        <Modal
            visible={ModalVisible}
            title={IsEdit ? "Düzenle" : "Yeni"}
            onOk={Submit}
            onCancel={Cancel}
            footer={[
                <Button key="back" onClick={Cancel}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={Loading} onClick={Submit}>
                    { IsEdit ? "Güncelle" : "Yeni" }
                </Button>
            ]}
        >
            <Form
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={Item}
                form={form}
            >
                <Form.Item name="name" label={'Ad Soyad'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="Ad" />
                </Form.Item>

                <Form.Item name="email" label={'E-Mail'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="E-Mail" />
                </Form.Item>

                <Form.Item name="password" label={'Parola'} rules={[{required: !IsEdit}]}>
                    <Input type={"password"} autoComplete={"new-password"} placeholder="Parola" />
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default EditModal;