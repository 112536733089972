import React from "react";
import {Avatar, Button, Layout, Menu, Space} from "antd";
import {LockOutlined, MenuOutlined} from "@ant-design/icons";
import authStore from "../../store/AuthStore";

const { Header } = Layout;
const { SubMenu } = Menu;

const AppHeader = (props) => {
    const {toggleCollapse} = props
    const user = authStore.getState().user
    const logout = async () => {
        await authStore.getState().signOut()
    }
    return (
        <Header className="header" style={{ position: 'fixed', zIndex: 100, width: '100%' }}>
            <div className="logo" />
            <div className="toggle-button">
                <Button type="default" size="small" onClick={toggleCollapse}>
                    <MenuOutlined style={{color: '#000', fontSize: 16}} />
                </Button>
            </div>
            <div className="action-area">
                <Space>
                    <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle', horizonAlign: 'end' }} size="large" gap={4}>
                        {user.email[0].toLocaleUpperCase('tr-TR')}
                    </Avatar>
                    <Menu theme="dark" mode="horizontal">
                        <SubMenu key={'123'} title={'Hoşgeldiniz ' + user.name}>
                            {/*<Menu.Item key="1">nav 1</Menu.Item>*/}
                            {/*<Menu.Item key="2">nav 2</Menu.Item>*/}
                            <Menu.Item key="logout" icon={<LockOutlined/>}>
                                <a onClick={logout}> Çıkış Yap</a>
                            </Menu.Item>
                        </SubMenu>

                    </Menu>
                </Space>
            </div>
        </Header>
    )
}

export default AppHeader