import React from "react";
import {Route, Switch} from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";

const Auth = () => {
    return (
        <Switch>
            <Route exact path={"/auth/login"} component={Login} />
            <Route exact path={"/auth/forgot-password"} component={ForgotPassword} />
        </Switch>
    )
}

export default Auth