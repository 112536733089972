import React, {useState} from "react";
import {
    Alert,
    Button,
    Card,
    Cascader,
    Form,
    Input,
    InputNumber,
    List,
    message,
    Modal,
    Tabs,
    Upload
} from "antd";
import shallow from 'zustand/shallow'
import useCrudStore from "../../../store/CrudStore";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useAuthStore from "../../../store/AuthStore";
import {UploadOutlined} from "@ant-design/icons";

const EditModal = ({ FetchUrl }) => {
    const [form] = Form.useForm()
    const { Update, Create, Cancel, FetchRecords } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const [Item, Loading, ModalVisible, AdditionalData] = useCrudStore(state => [state.Item, state.Loading, state.ModalVisible, state.AdditionalData], shallow)
    const [FailedUploads, SetFailedUploads] = useState([]);
    const token = useAuthStore(state => state.token)

    const IsEdit = Item.action === 'edit'

    const CurrentCategory = AdditionalData.stock_unit_options.find((item) => item.items.map(x => x.id).includes(Item.stock_unit_id))
    const CurrentMainCategory = AdditionalData.stock_unit_options.find((item) => item.items.map(x => x.id).includes(Item.main_stock_unit_id))
    if (typeof CurrentCategory !== "undefined")
        Item.stock_unit_id = [CurrentCategory.id, Item.stock_unit_id]
    if (typeof CurrentMainCategory !== "undefined")
        Item.main_stock_unit_id = [CurrentMainCategory.id, Item.main_stock_unit_id]

    const Submit = () => {
        form.validateFields()
            .then(values => {
                values.stock_unit_id = values.stock_unit_id[1]
                if (values.main_stock_unit_id !== null)
                    values.main_stock_unit_id = values.main_stock_unit_id[1]
                if (IsEdit)
                    Update(Item.key, {stock_item: values})
                else
                    Create({stock_item: values})
            })
            .catch(errors => {
                console.log(errors)
            })
    }


    const uploaderProps = {
        name: 'document',
        action: '/api/v1/stock_item_files',
        headers: {
            "access-token": token.access_token,
            "uid": token.uid,
            "client": token.client
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} dosyasından ${info.file.response.success_count} kayıt başarıyla yüklendi`);
                SetFailedUploads(info.file.response.fails)
                FetchRecords()
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} dosyası yüklenirken hata oluştu.`);
            }
        }
    }

    const { TabPane } = Tabs;
    return (
        <Modal
            visible={ModalVisible}
            title={IsEdit ? "Düzenle" : "Yeni"}
            onOk={Submit}
            onCancel={Cancel}
            footer={[
                <Button key="back" onClick={Cancel}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={Loading} onClick={Submit}>
                    { IsEdit ? "Güncelle" : "Yeni" }
                </Button>
            ]}
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="Tekli Kayıt" key="1">
                    <Form
                        layout="horizontal"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={Item}
                        form={form}
                    >
                        <Form.Item name="stock_code" label={'Stok Kodu'} rules={[{required: true}]}>
                            <Input autoComplete="off" placeholder="Stok Kodu" />
                        </Form.Item>
                        <Form.Item name="name" label={'Ad'} rules={[{required: true}]}>
                            <Input autoComplete="off" placeholder="Kısaltma" />
                        </Form.Item>
                        <Form.Item name="stock_unit_id" label={'Varsayılan Giriş Birimi'} rules={[{required: true}]}>
                            <Cascader
                                options={AdditionalData.stock_unit_options}
                                fieldNames={{label: 'name', value: 'id', children: 'items'}}
                                placeholder={'Seçiniz'}
                                showSearch={{ filter: (inputValue, path) => path.some(option => option.name.toLocaleLowerCase('tr').indexOf(inputValue.toLocaleLowerCase('tr')) > -1) } }
                            />
                        </Form.Item>

                        <Form.Item name="main_stock_amount" label={'Dönüştürülecek Miktar'}>
                            <Input autoComplete="off" placeholder="Dönüştürülecek Miktar" />
                        </Form.Item>

                        <Form.Item name="main_stock_unit_id" label={'Dönüştürülecek Birim'}>
                            <Cascader
                                options={AdditionalData.stock_unit_options}
                                fieldNames={{label: 'name', value: 'id', children: 'items'}}
                                placeholder={'Seçiniz'}
                                showSearch={{ filter: (inputValue, path) => path.some(option => option.name.toLocaleLowerCase('tr').indexOf(inputValue.toLocaleLowerCase('tr')) > -1) } }
                            />
                        </Form.Item>
                        <Form.Item name="outage_rate" label={'Zayi Oranı (%)'} rules={[{required: true}]}>
                            <InputNumber min={0} max={100} style={{width: '100%'}} autoComplete="off" placeholder="Zayi Oranı (%)" />
                        </Form.Item>
                    </Form>
                </TabPane>
                <TabPane tab="Excel Olarak Yükle" key="2">
                    <Upload {...uploaderProps}>
                        <Button icon={<UploadOutlined />}>Excel dosyası seçiniz</Button>
                    </Upload>

                    {
                        FailedUploads.length > 0 ? (
                            <Card title={"İşlenemeyen kayıtlar"} type={"inner"}>
                                <List
                                    bordered
                                    dataSource={FailedUploads}
                                    renderItem={item => (
                                        <List.Item>
                                            <Alert message={item} type="error" />
                                        </List.Item>
                                    )}
                                />
                            </Card>
                        ) : null
                    }

                </TabPane>
            </Tabs>

        </Modal>
    )
}

export default EditModal;