import React, {useEffect, useState} from "react";
import App from "./App";
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import Auth from "./Pages/Auth/Auth";
import useAuthStore from "../store/AuthStore";
import http from "../http/http-common";
import {ConfigProvider, Space, Spin} from "antd";
import trTr from "antd/lib/locale/tr_TR"

const Loader = () => {
    const [isLoading, setIsLoading] = useState(true)
    const isAuthenticated = useAuthStore(state => state.isAuthenticated)
    const resetData = useAuthStore(state => state.resetData)
    const CheckLogin = () => {
        http().get('/api/v1/api_test')
            .then(() => {
                setIsLoading(false)
            })
            .catch(() => {
                useAuthStore.getState().resetData()
                setIsLoading(false)
            })

    }

    useEffect(() => {
        CheckLogin()
    }, [])

    if (!isAuthenticated){
        resetData()
    }

    if (isLoading) {
        return  (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: '200px'}}>
                <Space size="middle">
                    <Spin size="large" />
                </Space>
            </div>
        )
    }

    const AuthRoute = ({ component: Component, ...rest }) => {
        return (
            <Route
                {...rest}
                render={props => isAuthenticated ? ( <Redirect to={{ pathname: "/" }} /> ) : ( <Component {...props} /> ) }
            />
        );
    }

    const PrivateRoute = ({ component: Component, ...rest }) => {
        if (location.pathname.includes("/auth/"))
            return (
                <Route
                    {...rest}
                    render={props => isAuthenticated ? ( <Component {...props} /> ) : null }
                />
            )
        return (
            <Route
                {...rest}
                render={props => isAuthenticated ? ( <Component {...props} /> ) : ( <Redirect to={{ pathname: "/auth/login" }} /> ) }
            />
        );
    }

    return (
        <ConfigProvider locale={trTr}>
            <Router>
                <PrivateRoute path="/" component={App} />
                <AuthRoute path="/auth" component={Auth} />
            </Router>
        </ConfigProvider>
    )
}

export default Loader;