import React, {useEffect, useRef, useState} from "react";
import {Button, Input, Space, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import StoreCrudHelper from "../../Utils/StoreCrudHelper";
import useCrudStore from "../../store/CrudStore";

const AppTable = ({ RowSelection, TableKey, rowKey, columns, FetchUrl, Store, Style, ReloadTable, SetReloadTable }) => {
    if (!Style)
        Style = {}
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    if (typeof Store === "undefined")
        Store = useCrudStore
    const { FetchRecords } = new StoreCrudHelper(FetchUrl, Store)

    const Items = Store( state => state.Data )
    const Loading = Store( state => state.Loading )
    const Pagination = Store( state => state.Pagination )
    const FilterOptions = Store( state => state.FilterOptions )

    useEffect(() => {
        FetchRecords()
    }, [])

    useEffect(() => {
        console.log("ReloadTable", ReloadTable)
        if (ReloadTable) {
            console.log(Pagination, FilterOptions)
            FetchRecords(Pagination.current, Store().FilterOptions, Pagination.pageSize)
            //SetReloadTable(false)
        }
    }, [ReloadTable])

    const searchInput = useRef(null)

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={'Ara'}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Ara
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Sıfırla
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLocaleLowerCase('tr').includes(value.toLocaleLowerCase('tr'))
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };

    const filteredColumns = columns.map( c => c.hasOwnProperty('searchKey') ? {...c, ...getColumnSearchProps(c.searchKey)} : c )

    const TableChange = (pagination, filter) => {
        Store.setState({FilterOptions: filter})
        FetchRecords(pagination.current, filter, pagination.pageSize)
    }

    if (rowKey)
        return <Table key={TableKey ? TableKey : "ItemTable"} style={Style} rowKey={rowKey} dataSource={Items} onChange={TableChange} loading={Loading} pagination={Pagination} columns={filteredColumns} />
    else if (RowSelection)
        return <Table key={TableKey ? TableKey : "ItemTable"} rowSelection={RowSelection} style={Style} rowKey={rowKey} dataSource={Items} onChange={TableChange} loading={Loading} pagination={Pagination} columns={filteredColumns} />
    else
        return <Table key={TableKey ? TableKey : "ItemTable"} style={Style} dataSource={Items} onChange={TableChange} loading={Loading} pagination={Pagination} columns={filteredColumns} />
}

export default AppTable;