import React from "react";
import axios from "axios";
import Qs from "qs";
import useAuthStore from "../store/AuthStore";

axios.interceptors.request.use(config => {
    window.console.log(config);
    config.paramsSerializer = params => {
        // Qs is already included in the Axios package
        return Qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false
        });
    };

    return config;
});


const http = () => {
    const csrf_token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
    const token = useAuthStore.getState().token
    return axios.create({
        baseURL: location.origin,
        headers: {
            "Content-type": "application/json",
            "access-token": token.access_token,
            "uid": token.uid,
            "client": token.client,
            'X-CSRF-Token': csrf_token.content
        }
    });
}
export default http