import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {ArrowLeftOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Card, Cascader, Form, Input, message, Popconfirm, Space} from "antd";
import shallow from "zustand/shallow";
import useCrudStore from "../../../store/CrudStore";
import useSubStore from "../../../store/SubStore";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import AppTable from "../../Generic/AppTable";
import RecipeItemModal from "./RecipeItemModal";
import NotFound from "../../Generic/NotFound";

const RecipeDetail = () => {
    const [Item, Loading, AdditionalData] = useCrudStore(state => [state.Item, state.Loading, state.AdditionalData], shallow)
    const ModalVisible = useSubStore(state => state.ModalVisible)
    const [ItemNotFound, SetItemNotFound] = useState(false)
    const [PageActive, SetPageActive] = useState(false)
    const routeParams = useParams();
    const history = useHistory();

    useEffect(() => {
        useCrudStore.getState().Reset()
        useSubStore.getState().Reset()
        FetchRecord(routeParams.id).then().catch(() => {SetItemNotFound(true)})
    }, [])

    useEffect(() => {
        try {
            const CurrentCategory = AdditionalData.stock_unit_options.find((item) => item.items.map(x => x.id).includes(Item.stock_unit_id))
            if (typeof CurrentCategory !== "undefined")
                Item.stock_unit_id = [CurrentCategory.id, Item.stock_unit_id]
            SetPageActive(true)
        } catch {}
    }, [Item, AdditionalData])

    const { FetchRecord, Update } = new StoreCrudHelper('/api/v1/recipes', useCrudStore)
    const RecipeItemHelper = new StoreCrudHelper(`/api/v1/recipes/${routeParams.id}/recipe_items`, useSubStore)

    const columns = [
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            searchKey: 'name',
            responsive: ['md'],
        },
        {
            title: 'Maliyet',
            dataIndex: 'cost_with_unit',
            key: 'cost_with_unit',
            responsive: ['md']
        },
        {
            title: 'Tip',
            dataIndex: 'consumable_type',
            key: 'consumable_type',
            responsive: ['md'],
            render: (text) => text === "Recipe" ? "Reçete" : "Stoklu Ürün"
        },
        {
            title: 'Kullanım Miktarı',
            dataIndex: 'usage_amount',
            key: 'usage_amount',
            responsive: ['md'],
        },
        {
            title: 'Zayi Oranı (%)',
            dataIndex: 'outage_rate',
            key: 'outage_rate',
            responsive: ['md'],
            render: text => `% ${text}`
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                if (!record.is_base) {
                    return (
                        <Space size="middle">

                            <a onClick={() => RecipeItemHelper.Edit(record.key)}><EditOutlined/></a>
                            <Popconfirm
                                title="Silmek istediğinize emin misiniz?"
                                onConfirm={() => {RecipeItemHelper.Remove(record.key)}}
                                okText="Sil"
                                cancelText="İptal"
                            >
                                <a href="#"><DeleteOutlined/></a>
                            </Popconfirm>
                        </Space>
                    )
                }
            },
        }
    ];

    if (ItemNotFound)
        return <NotFound />

    const [form] = Form.useForm()

    const Submit = () => {
        form.validateFields()
            .then(values => {
                values.stock_unit_id = values.stock_unit_id[1]
                Update(routeParams.id, {recipe: values})
                    .then(() => {
                        message.success('Başarıyla güncellendi');
                        FetchRecord(routeParams.id).then(() => {SetPageActive(true)}).catch(() => {SetItemNotFound(true)})
                    })
                    .catch(() => {
                        message.error('Bir Hata Oluştu')
                    })
            })
            .catch(errors => {
                console.log(errors)
            })
    }

    const FormItem = (label, name, rules = [], opts = {}) => {
        return (
            <Card.Grid hoverable={false} style={{width: '25%'}}>
                <Form.Item name={name} label={label} rules={rules}>
                    <Input autoComplete="off" placeholder={label} {...opts}/>
                </Form.Item>
            </Card.Grid>
        )
    }

    if (!PageActive || Object.keys(AdditionalData).length === 0)
        return (
            <Card loading={true} />
        )

    return (
        <Card title={
            <Space direction={'vertical'}>
                <Space>
                    <Button type={"primary"} onClick={() => history.goBack()} ><ArrowLeftOutlined/></Button>
                    {`${Item.name} Reçete Detayı`}
                </Space>
                <Space style={{marginTop: 10}}>
                    <p>Maliyet: {Item.cost_with_unit}</p>
                </Space>
            </Space>
        } loading={Loading}>
            <Card type="inner" title={"Giderler"} style={ {marginBottom: 10} }>
                <Form
                    layout={'inline'}
                    form={form}
                    initialValues={Item}
                >

                        { FormItem("Üretim Miktarı", "production_amount", [{required: true}]) }
                        <Card.Grid hoverable={false} style={{width: '25%'}}>
                            <Form.Item name='stock_unit_id' label={'Üretim Bİrimi'} rules={[{required: true}]}>
                                <Cascader
                                    options={AdditionalData.stock_unit_options}
                                    fieldNames={{label: 'name', value: 'id', children: 'items'}}
                                    placeholder={'Seçiniz'}
                                    showSearch={{ filter: (inputValue, path) => path.some(option => option.name.toLocaleLowerCase('tr').indexOf(inputValue.toLocaleLowerCase('tr')) > -1) } }
                                />
                            </Form.Item>
                        </Card.Grid>
                        { FormItem("Personel Gideri", "labor_cost", [{required: true}]) }
                        { FormItem("Paketleme Gideri", "packaging_cost", [{required: true}]) }
                        { FormItem("Nakliye Gideri", "transfer_cost", [{required: true}]) }
                        { FormItem("Diğer Giderler", "other_costs", [{required: true}]) }
                        { FormItem("Zayi Oranı (%)", "outage_rate", [{required: true}], {min: 0, max: 100, value: 0}) }
                        <Card.Grid hoverable={false} style={{width: '25%', textAlign: 'center'}}>
                            <Form.Item>
                                <Button type="primary" onClick={Submit}>Güncelle</Button>
                            </Form.Item>
                        </Card.Grid>
                </Form>
            </Card>
            <Card type="inner" title="Reçete Detayı" extra={
                <Space>
                    <Button type={"primary"} onClick={() => RecipeItemHelper.New(true)}><PlusOutlined/>İçerik Ekle</Button>
                </Space>}
            >
                <AppTable columns={ columns } FetchUrl={`/api/v1/recipes/${routeParams.id}/recipe_items`} Store={useSubStore}/>
                { ModalVisible ? <RecipeItemModal FetchUrl={`/api/v1/recipes/${routeParams.id}/recipe_items`} /> : null }
            </Card>
        </Card>
    )
}

export default RecipeDetail;