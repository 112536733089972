import React, {useEffect, useState} from "react";
import {Button, Card, Popconfirm, Space, Tag} from "antd";
import {
    ArrowLeftOutlined,
    BackwardOutlined, CopyOutlined, DeleteOutlined,
    EditOutlined, EyeOutlined, PlusOutlined,
} from "@ant-design/icons";
import AppTable from "../../Generic/AppTable";
import EditModal from "./EditModal";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";
import {Link, useHistory, useParams} from "react-router-dom";
import http from "../../../http/http-common";
import useSubStore from "../../../store/SubStore";

const Recipes = () => {
    let { category_id } = useParams();

    const FetchUrl = typeof category_id === "undefined" ? '/api/v1/recipes' : `/api/v1/recipe_categories/${category_id}/recipes`
    const { New, Edit, Clone, Remove } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const ModalVisible = useCrudStore(state => state.ModalVisible)
    const [Category, SetCategory] = useState("")
    const SecondStore = useSubStore
    const SecondHelper = new StoreCrudHelper(FetchUrl, SecondStore)
    useEffect(() => {
        useCrudStore.getState().Reset()
        http()
            .get(`/api/v1/recipe_categories/${category_id}`)
            .then(response => {
                SetCategory(response.data.data.name)
            })
    }, [])

    const columns = [
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            searchKey: 'name',
            responsive: ['md']
        },
        {
            title: 'Maliyet',
            dataIndex: 'cost_with_unit',
            key: 'cost_with_unit',
            responsive: ['md'],
            render: (text, record) => {
                if (record.final_product) {
                    return (
                        <Tag color={'green'}>
                            {text} (Nihai Ürün)
                        </Tag>
                    )
                } else {
                    return text
                }
            }
        },
        {
            title: 'Personel Gideri',
            dataIndex: 'labor_cost',
            key: 'labor_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Paketleme Gideri',
            dataIndex: 'packaging_cost',
            key: 'packaging_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Nakliye Gideri',
            dataIndex: 'transfer_cost',
            key: 'transfer_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Diğer Giderler',
            dataIndex: 'other_costs',
            key: 'other_costs',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Zayi Oranı',
            dataIndex: 'outage_rate',
            key: 'outage_rate',
            responsive: ['md'],
            render: text => `% ${text}`
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                if (!record.is_base) {
                    return (
                        <Space size="middle">
                            <a href={typeof category_id === "undefined" ? `/receteler/${record.key}` : `/recete-kategorileri/${category_id}/receteler/${record.key}`}><EyeOutlined/></a>
                            <a onClick={() => Edit(record.key)}><EditOutlined/></a>
                            <Popconfirm
                                title="Kopyalamak istediğinize emin misiniz?"
                                onConfirm={() => {Clone(record.key)}}
                                okText="Kopyala"
                                cancelText="İptal"
                            >
                                <a href="#"><CopyOutlined/></a>
                            </Popconfirm>
                            <Popconfirm
                                title="Silmek istediğinize emin misiniz?"
                                onConfirm={() => {Remove(record.key)}}
                                okText="Sil"
                                cancelText="İptal"
                            >
                                <a href="#"><DeleteOutlined/></a>
                            </Popconfirm>
                        </Space>
                    )
                }
            },
        }
    ];

    const secondColumns = [
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            searchKey: 'name',
            responsive: ['md']
        },
        {
            title: 'Maliyet',
            dataIndex: 'cost_with_unit',
            key: 'cost_with_unit',
            responsive: ['md'],
            render: (text, record) => {
                if (record.final_product) {
                    return (
                        <Tag color={'green'}>
                            {text} (Nihai Ürün)
                        </Tag>
                    )
                } else {
                    return text
                }
            }
        },
        {
            title: 'Personel Gideri',
            dataIndex: 'labor_cost',
            key: 'labor_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Paketleme Gideri',
            dataIndex: 'packaging_cost',
            key: 'packaging_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Nakliye Gideri',
            dataIndex: 'transfer_cost',
            key: 'transfer_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Diğer Giderler',
            dataIndex: 'other_costs',
            key: 'other_costs',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Zayi Oranı',
            dataIndex: 'outage_rate',
            key: 'outage_rate',
            responsive: ['md'],
            render: text => `% ${text}`
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                if (!record.is_base) {
                    return (
                        <Space size="middle">
                            <a href={typeof category_id === "undefined" ? `/receteler/${record.key}` : `/recete-kategorileri/${category_id}/receteler/${record.key}`}><EyeOutlined/></a>
                            <a onClick={() => Edit(record.key)}><EditOutlined/></a>
                            <Popconfirm
                                title="Kopyalamak istediğinize emin misiniz?"
                                onConfirm={() => {
                                    SecondHelper.Clone(record.key).then(() => {
                                        location.reload()
                                    })
                                }}
                                okText="Kopyala"
                                cancelText="İptal"
                            >
                                <a href="#"><CopyOutlined/></a>
                            </Popconfirm>
                            <Popconfirm
                                title="Silmek istediğinize emin misiniz?"
                                onConfirm={() => {SecondHelper.Remove(record.key)}}
                                okText="Sil"
                                cancelText="İptal"
                            >
                                <a href="#"><DeleteOutlined/></a>
                            </Popconfirm>
                        </Space>
                    )
                }
            },
        }
    ];

    return (
        <Card title={
            <Space>
                <Button type={"primary"} onClick={useHistory().goBack} ><ArrowLeftOutlined/></Button>
                {`${Category} Reçeteleri`}
            </Space>
        } extra={<Button type='primary' onClick={New}><PlusOutlined/>Reçete Ekle</Button>}>

            <Card type={'inner'} title={"Nihai Ürünler"}>
                <AppTable TableKey={"Nihai Ürünler"} columns={columns} category_id={category_id} FetchUrl={`${FetchUrl}?only_final=1`}/>
            </Card>

            <Card type={'inner'} title={"Alt Ürünler"}>
                <AppTable TableKey={"Alt Ürünler"} columns={secondColumns} category_id={category_id} Store={SecondStore} FetchUrl={`${FetchUrl}?unless_final=1`}/>
            </Card>

            { ModalVisible ? <EditModal category_id={category_id} FetchUrl={FetchUrl} /> : null }
        </Card>
    )
}

export default Recipes