import create from "zustand";

const DefaultItem = {action: 'new'}
const Default = {
    AdditionalData: {},
    DefaultItem: DefaultItem,
    ModalVisible: false,
    Item: DefaultItem,
    Loading: true,
    Data: [],
    FilterOptions: {},
    Pagination: {current: 1, pageSize: 1, hideOnSinglePage: true, pageSizeOptions: [] }
}
const useSubStore = create(set => (
    {
        ...Default,
        ...{
            Reset: () => {
                set(state => ({...Default}))
            }
        }
    }
))

export default useSubStore