import React from "react";
import {Button, Card, Space} from "antd";
import {
    EditOutlined, PlusOutlined,
} from "@ant-design/icons";
import AppTable from "../../Generic/AppTable";
import EditModal from "./EditModal";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";

const StockUnit = () => {
    const FetchUrl = '/api/v1/stock_units'
    const { New, Edit } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const ModalVisible = useCrudStore(state => state.ModalVisible)

    const columns = [
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            searchKey: 'name',
            responsive: ['md'],
        },
        {
            title: 'Kısaltma',
            dataIndex: 'abbr',
            key: 'abbr',
            searchKey: 'abbr',
        },
        {
            title: 'Oran',
            dataIndex: 'rate_text',
            key: 'rate_text',
            responsive: ['md'],
        },
        {
            title: 'Birim Tipi',
            dataIndex: 'unit_type_text',
            key: 'unit_type_text',
            responsive: ['md'],
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                if (!record.is_base) {
                    return (
                        <Space size="middle">
                            <a onClick={() => Edit(record.key)}><EditOutlined/></a>
                        </Space>
                    )
                }
            },
        }
    ];

    return (
        <Card title="Birimler" extra={<Button type='primary' onClick={New}><PlusOutlined/></Button>}>
            <AppTable columns={columns} FetchUrl={FetchUrl}/>
            { ModalVisible ? <EditModal FetchUrl={FetchUrl} /> : null }
        </Card>
    )
}

export default StockUnit