import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {Link, matchPath, useLocation} from "react-router-dom";
import {
    UserOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const routes = [
    {
        key: "Menu-Dashboard",
        text: "Pano",
        path: "/",
        exact: true,
        icon: <UserOutlined />
    },
    {
        key: "Menu-StockUnits",
        text: "Birimler",
        path: "/birimler",
        exact: true,
        icon: <UserOutlined />
    },
    {
        key: "Menu-StockItems",
        text: "Hammaddeler",
        path: "/hammaddeler",
        exact: true,
        icon: <UserOutlined />
    },
    {
        key: "Menu-RecipeCategories",
        text: "Ürün Grupları",
        path: "/recete-kategorileri",
        exact: false,
        icon: <UserOutlined />
    },
    {
        key: "Menu-Users",
        text: "Kullanıcılar",
        path: "/kullanicilar",
        exact: false,
        icon: <UserOutlined />
    },
    // {
    //     key: "Menu-ProductionLists",
    //     text: "Üretim Listesi",
    //     path: "/uretim-listesi",
    //     exact: false,
    //     icon: <UserOutlined />
    // },
    {
        key: "Menu-Audits",
        text: "Sistem Hareketleri",
        path: "/hareketler",
        exact: false,
        icon: <UserOutlined />
    },
    // ,
    // {
    //     key: "Menu-Recipes",
    //     text: "Reçeteler",
    //     path: "/receteler",
    //     exact: false,
    //     icon: <UserOutlined />
    // }
]

const AppSidebar = (props) => {
    const [activeMenu, setActiveMenu] = useState("")
    const location = useLocation()
    const { width, isCollapsed, onCollapse} = props

    useEffect(() => {
        const currentRoute = routes.find(route => matchPath(location.pathname, route))
        if (typeof currentRoute !== "undefined")
            setActiveMenu(currentRoute.key)
    }, [location])


    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={isCollapsed}
            breakpoint="md"
            collapsedWidth="0"
            width={width}
            mode="inline"
            onCollapse={onCollapse}
            className="site-layout-background"
            style={{
                zIndex: 100,
                overflow: 'auto',
                height: '100vh',
                left: 0,
                position: 'fixed',
                top: '64px'
            }}
        >
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['Menu-Dashboard']} selectedKeys={[activeMenu]}>
                { routes.map( route =>
                    <Menu.Item key={route.key} icon={route.icon}>
                        <a href={route.path}>{route.text}</a>
                    </Menu.Item>
                ) }
            </Menu>
        </Sider>
    )
}

export default AppSidebar