import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    Cascader,
    Form,
    Input,
    InputNumber,
    List,
    message,
    Modal,
    Tabs,
    Typography,
    Upload
} from "antd";
import useAuthStore from "../../../store/AuthStore";
import http from "../../../http/http-common";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";

const CostModal = ({ FetchUrl, CostStockItemId, CostModalActive, SetCostModalActive, SetReloadTable }) => {
    const [form] = Form.useForm()
    const token = useAuthStore(state => state.token)
    const [Loading, SetLoading] = useState(false)
    // const [ModalVisible, SetModalVisible] = useState(false)
    const Item = {price: null, stock_item_id: CostStockItemId}
    const [AdditionalData, SetAdditionalData] = useState({stock_unit_options: []})

    const Submit = () => {
        form.validateFields()
            .then(values => {
                SetLoading(true)
                let attributes = {
                    item_cost: {
                        resource_type: 'StockItem',
                        resource_id: CostStockItemId,
                        price: values.price,
                        stock_unit_id: values.main_stock_unit_id[1]
                    }
                }
                http().post('/api/v1/item_costs', attributes).then(response => {
                    if (response.data.success) {
                        SetLoading(false)
                        SetCostModalActive(false)
                        let stock_item = response.data.data.key
                        let tableData = useCrudStore.getState().Data
                        const newData = tableData.map(obj => {
                            if (obj.key === stock_item) {
                                return {...obj, ...response.data.data};
                            }

                            return obj;
                        });

                        useCrudStore.setState({Data: newData})

                        //SetReloadTable(true)
                    }
                })
            })
            .catch(errors => {
                console.log(errors)
            })
    }

    const Cancel = () => {
        SetCostModalActive(false)
    }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetch('/api/v1/stock_items/' + CostStockItemId, {
            signal: signal,
            headers: {
                "Content-type": "application/json",
                "access-token": token.access_token,
                "uid": token.uid,
                "client": token.client
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.hasOwnProperty('additional_data')) {
                    SetAdditionalData(response.additional_data)
                }
            });
        return () => {
            controller.abort();
        }
    }, [])

    return (
        <Modal
            visible={CostModalActive}
            title={"Maliyet Ekle"}
            onOk={Submit}
            onCancel={Cancel}
            footer={[
                <Button key="back" onClick={Cancel}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={Loading} onClick={Submit}>
                    MaliyetEkle
                </Button>
            ]}
        >
            <Form
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={Item}
                form={form}
            >
                {/*<Form.Item name="stock_item_id" label={'Stoklu Ürün'}>*/}
                {/*    <Input autoComplete="off" placeholder="Stoklu Ürün" type={"hidden"} />*/}
                {/*</Form.Item>*/}

                <Form.Item name="price" label={'Fiyat'}>
                    <Input autoComplete="off" placeholder="Fiyat" />
                </Form.Item>

                <Form.Item name="main_stock_unit_id" label={'Birim'}>
                    <Cascader
                        options={AdditionalData.stock_unit_options}
                        fieldNames={{label: 'name', value: 'id', children: 'items'}}
                        placeholder={'Seçiniz'}
                        showSearch={{ filter: (inputValue, path) => path.some(option => option.name.toLocaleLowerCase('tr').indexOf(inputValue.toLocaleLowerCase('tr')) > -1) } }
                    />
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default CostModal;