import React from "react";
import {Form, Input, Button, Layout, Card, Col, Row} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";

const ForgotPassword = () => {
    const onFinish = values => {
        console.log('Received values of form: ', values);
    };

    return (
        <Layout>
            <Row gutter={12} style={{height: '100vh'}}>
                <Col xs={{span: 20, offset: 2}} lg={{span: 12, offset: 6}}>
                    <Card style={{marginTop: 50}}>
                        <Form
                            style={{margin: '0 auto'}}
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                        >
                            <h2>Parolanızı mı unuttunuz?</h2>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'E-Mail adresi giriniz!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-Mail" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Giriş Yap
                                </Button>

                                <Button type="default" htmlType="button" style={{marginTop: 10}} className="login-form-button">
                                    <Link to={"/auth/login"}>Geri</Link>
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default ForgotPassword