import React from "react";
import {Button, Form, Input, Modal} from "antd";
import shallow from 'zustand/shallow'
import useCrudStore from "../../../store/CrudStore";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";

const EditModal = ({ FetchUrl }) => {
    const [form] = Form.useForm()
    const { Update, Create, Cancel } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const [Item, Loading, ModalVisible] = useCrudStore(state => [state.Item, state.Loading, state.ModalVisible], shallow)

    const IsEdit = Item.action === 'edit'

    const Submit = () => {
        form.validateFields()
            .then(values => {
                if (IsEdit)
                    Update(Item.key, {recipe_category: values})
                else
                    Create({recipe_category: values})
            })
            .catch(errors => {
                console.log(errors)
            })
    }

    return (
        <Modal
            visible={ModalVisible}
            title={IsEdit ? "Düzenle" : "Yeni"}
            onOk={Submit}
            onCancel={Cancel}
            footer={[
                <Button key="back" onClick={Cancel}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={Loading} onClick={Submit}>
                    { IsEdit ? "Güncelle" : "Yeni" }
                </Button>
            ]}
        >
            <Form
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={Item}
                form={form}
            >
                <Form.Item name="name" label={'Reçete Grubu'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="Reçete Grubu" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditModal;