import React from "react";
import {Button, Form, Input, Modal, Switch} from "antd";
import shallow from 'zustand/shallow'
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";

const EditModal = ({ FetchUrl, category_id }) => {
    const [form] = Form.useForm()
    const { Update, Create, Cancel } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const [Item, Loading, ModalVisible] = useCrudStore(state => [state.Item, state.Loading, state.ModalVisible], shallow)
    const IsEdit = Item.action === 'edit'

    const Submit = () => {
        form.validateFields()
            .then(values => {
                console.log(values)
                if (typeof category_id !== "undefined")
                    values.recipe_category_id = category_id
                if (IsEdit)
                    Update(Item.key, {recipe: values}).then(() => location.reload())
                else
                    Create({recipe: values}).then(() => location.reload())
            })
            .catch(errors => {
                console.log(errors)
            })
    }

    return (
        <Modal
            visible={ModalVisible}
            title={IsEdit ? "Düzenle" : "Yeni"}
            onOk={Submit}
            onCancel={Cancel}
            footer={[
                <Button key="back" onClick={Cancel}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={Loading} onClick={Submit}>
                    { IsEdit ? "Güncelle" : "Yeni" }
                </Button>
            ]}
        >
            <Form
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={Item}
                form={form}
            >
                <Form.Item name="name" label={'Ad'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="Ad" />
                </Form.Item>

                <Form.Item name="final_product" valuePropName="checked" label={'Nihai Ürün'} rules={[{required: true}]}>
                    <Switch defaultChecked={Item.final_product} />
                </Form.Item>

                {/*<Form.Item name="labor_cost" label={'Personel Gideri'} rules={[{required: true}]}>*/}
                {/*    <Input autoComplete="off" placeholder="Personel Gideri" />*/}
                {/*</Form.Item>*/}

                {/*<Form.Item name="packaging_cost" label={'Paketleme Gideri'} rules={[{required: true}]}>*/}
                {/*    <Input autoComplete="off" placeholder="Paketleme Gideri" />*/}
                {/*</Form.Item>*/}

                {/*<Form.Item name="transfer_cost" label={'Nakliye Gideri'} rules={[{required: true}]}>*/}
                {/*    <Input autoComplete="off" placeholder="Nakliye Gideri" />*/}
                {/*</Form.Item>*/}

                {/*<Form.Item name="other_costs" label={'Diğer Giderler'} rules={[{required: true}]}>*/}
                {/*    <Input autoComplete="off" placeholder="Diğer Giderler" />*/}
                {/*</Form.Item>*/}

                {/*<Form.Item name="outage_rate" label={'Zayi Oranı (%)'} rules={[{required: true}]}>*/}
                {/*    <Input autoComplete="off" placeholder="Zayi Oranı (%)" min={0} max={100} value={0} />*/}
                {/*</Form.Item>*/}
            </Form>
        </Modal>
    )
}

export default EditModal;