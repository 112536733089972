import React from "react";
import {Button, Form, Input, Modal, Cascader} from "antd";
import shallow from 'zustand/shallow'
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";

const EditModal = ({ FetchUrl }) => {
    const [form] = Form.useForm()
    const { Update, Create, Cancel } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const [Item, Loading, ModalVisible, AdditionalData] = useCrudStore(state => [state.Item, state.Loading, state.ModalVisible, state.AdditionalData], shallow)
    const IsEdit = Item.action === 'edit'

    const Submit = () => {
        form.validateFields()
            .then(values => {
                values.unit_type = values.unit_type_and_item[0]
                values.stock_unit_id = values.unit_type_and_item[1]
                if (IsEdit)
                    Update(Item.key, {stock_unit: values})
                else
                    Create({stock_unit: values})
            })
            .catch(errors => {
                console.log(errors)
            })
    }

    return (
        <Modal
            visible={ModalVisible}
            title={IsEdit ? "Düzenle" : "Yeni"}
            onOk={Submit}
            onCancel={Cancel}
            footer={[
                <Button key="back" onClick={Cancel}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={Loading} onClick={Submit}>
                    { IsEdit ? "Güncelle" : "Yeni" }
                </Button>
            ]}
        >
            <Form
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={Item}
                form={form}
            >
                <Form.Item name="name" label={'Ad'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="Ad" />
                </Form.Item>

                <Form.Item name="abbr" label={'Kısaltma'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="Kısaltma" />
                </Form.Item>
                <Form.Item name="unit_type_and_item" label={'Birim Tipi'} rules={[{required: true}]}>
                    <Cascader
                        options={AdditionalData.stock_unit_options}
                        fieldNames={{label: 'name', value: 'id', children: 'items'}}
                        placeholder={'Seçiniz'}
                        showSearch={{ filter: (inputValue, path) => path.some(option => option.name.toLocaleLowerCase('tr').indexOf(inputValue.toLocaleLowerCase('tr')) > -1) } }
                    />
                </Form.Item>
                <Form.Item name="rate" label={'Oran'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="Oran" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditModal;