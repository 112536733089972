import React, {useEffect, useState} from "react";
import {Layout} from 'antd';
import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";
import AppBreadcrumb from "./AppBreadcrumb";
import {useLocation} from "react-router-dom";

const { Content } = Layout;

const PageLayout = ({children}) => {
    const [siderWidth, setSiderWidth] = useState(250)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isResponsive, setIsResponsive] = useState(false)

    const location = useLocation()
    useEffect(() => {
        if (isResponsive) onCollapse(true)
    }, [location])

    const toggleCollapse = () => {
        onCollapse(!isCollapsed, "buttonClick")
    }

    const onCollapse = (collapsed, type) => {
        if (!isResponsive && type === "responsive") setIsResponsive(true)
        setIsCollapsed(collapsed)
        if(collapsed)
            setSiderWidth(0)
        else
            setSiderWidth(250)
    }


    return (
        <Layout style={{overflow: 'hidden'}}>
            <AppHeader toggleCollapse={toggleCollapse} />
            <Layout>
                <AppSidebar width={siderWidth} isCollapsed={isCollapsed} onCollapse={onCollapse} />
                <Layout className="page-container" style={{ height: '100vh', padding: '0 8px 8px', marginLeft: isResponsive ? 0 : siderWidth }}>
                    <Content
                        className="site-layout-background"
                        style={{
                            zIndex: 5,
                            padding: 24,
                            margin: 0,
                            minHeight: 500,
                            overflow: 'auto'
                        }}
                    >
                        <AppBreadcrumb />
                        <div className="site-layout-background" style={{ minHeight: 360 }}>
                            { children }
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default PageLayout

