import React from "react";
import http from "../http/http-common";

class StoreCrudHelper {
    FetchUrl = ""
    Store = null

    constructor(_FetchUrl, _Store) {
        this.FetchUrl = _FetchUrl
        this.Store = _Store
    }

    FetchRecords = async (page = 1, searchQuery = {}, pageSize = 15) => {
        this.Store.setState({Loading: true, Data: []})
        let params = { page: page, per_page: pageSize, q: {} }
        Object.keys(searchQuery).map(function(key, _) {
            if (searchQuery[key] !== null)
                params.q[key + '_i_cont'] = Array.isArray(searchQuery[key]) ? searchQuery[key][0] : searchQuery[key]
        });
        const {Pagination} = this.Store.getState()
        try {
            const response = await http().get(this.FetchUrl, { params: params })
            this.Store.setState({Data: response.data.data, Pagination: {...Pagination, ...response.data.pagination}, Loading: false})
        } catch (ex) {
            this.Store.setState({Error: ex, Loading: false})
        }
    }

    FetchRecord = async (id) => {
        this.Store.setState({Loading: true})
        const response = await http().get(`${this.FetchUrl}/${id}`)
        let data = response.data
        let item = {...data.data}
        let stateObject = {Item: item, Loading: false}
        if (data.hasOwnProperty('additional_data')) {
            stateObject.AdditionalData = data.additional_data
        }
        this.Store.setState(stateObject)
    }

    New = async (forceOutage = false) => {
        this.Store.setState({ Loading: true })
        const response = await http().get(this.FetchUrl + '/new')
        let data = response.data
        let item = {...data.data, ...{action: 'new'}}
        if (forceOutage && !isNaN(item.outage_rate))
            item.outage_rate = 0
        let stateObject = {Item: item, ModalVisible: true, Loading: false}
        if (data.hasOwnProperty('additional_data')) {
            stateObject.AdditionalData = data.additional_data
        }
        this.Store.setState(stateObject)
    }

    Edit = async(id) => {
        const response = await http().get(this.FetchUrl + '/' + id)
        let data = response.data
        let item = {...data.data, ...{action: 'edit'}}
        let stateObject = {Item: item, ModalVisible: true}
        if (data.hasOwnProperty('additional_data')) {
            stateObject.AdditionalData = data.additional_data
        }
        this.Store.setState(stateObject)
    }

    Update = async (id, values) => {
        this.Store.setState({ Loading: true })
        const { FilterOptions, Pagination } = this.Store.getState()
        const response = await http().put(this.FetchUrl + '/' + id, values)
        const {FetchRecords, Store} = this
        if (response.data.success) {
            Store.setState({Item: this.Store.getState().DefaultItem, ModalVisible: false, Loading: false})
            await FetchRecords(Pagination.current, FilterOptions)
        }
    }

    Create = async (values) => {
        this.Store.setState({ Loading: true })
        const response = await http().post(this.FetchUrl, values)
        const {FetchRecords, Store} = this
        const { FilterOptions, Pagination } = Store.getState()
        if (response.data.success) {
            Store.setState({Item: this.Store.getState().DefaultItem, ModalVisible: false, Loading: false})
            await FetchRecords(Pagination.current, FilterOptions)
        }
    }

    Remove = async (id) => {
        this.Store.setState({ Loading: true })
        const response = await http().delete(this.FetchUrl + '/' + id)
        const {FetchRecords} = this
        if (response.data.success) {
            await FetchRecords()
        }
        this.Store.setState({ Loading: false })
    }

    Clone = async (id) => {
        this.Store.setState({ Loading: true })
        const response = await http().post(this.FetchUrl + '/' + id + '/clone')
        const {FetchRecords} = this
        if (response.data.success) {
            await FetchRecords()
        }
        this.Store.setState({ Loading: false })
    }

    Cancel = () => {
        this.Store.setState({Item: this.Store.getState().DefaultItem, ModalVisible: false})
    }

    RemoveFilter = async () => {
        this.Store.setState({FilterOptions: {}, Loading: true})
        await this.FetchRecords()
    }
}

export default StoreCrudHelper