import React from "react";
import {Button, Card, Popconfirm, Space} from "antd";
import {
    DeleteOutlined,
    EditOutlined, PlusOutlined,
} from "@ant-design/icons";
import AppTable from "../../Generic/AppTable";
import EditModal from "./EditModal";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";
import useAuthStore from "../../../store/AuthStore";

const Users = () => {
    const FetchUrl = '/api/v1/users'
    const { New, Edit, Remove } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const ModalVisible = useCrudStore(state => state.ModalVisible)

    const RemoveButton = (record) => {
        return (
            <Popconfirm
                title="Silmek istediğinize emin misiniz?"
                onConfirm={() => {Remove(record.key)}}
                okText="Sil"
                cancelText="İptal"
            >
                <a href="#"><DeleteOutlined/></a>
            </Popconfirm>
        )
    }

    const columns = [
        {
            title: 'Ad Soyad',
            dataIndex: 'name',
            key: 'name',
            searchKey: 'name',
            responsive: ['md'],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            searchKey: 'email',
            responsive: ['md'],
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <a onClick={() => Edit(record.key)}><EditOutlined/></a>
                        {(useAuthStore.getState().user.id !== record.key) ? RemoveButton(record) : null}
                    </Space>
                )
            },
        }
    ];

    return (
        <Card title="Kullanıcılar" extra={<Button type='primary' onClick={New}><PlusOutlined/></Button>}>
            <AppTable columns={columns} FetchUrl={FetchUrl}/>
            { ModalVisible ? <EditModal FetchUrl={FetchUrl} /> : null }
        </Card>
    )
}

export default Users