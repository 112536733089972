import create from 'zustand'
import {persist} from "zustand/middleware";
import http from "../http/http-common";

export const useAuthStore = create(persist(
    (set, get) => ({
        isAuthenticated: false,
        error: "",
        user: {},
        token: {},
        login: async payload => {
            try {
                const response = await http().post('/auth/sign_in', {
                    email: payload.email,
                    password: payload.password
                })
                let result = {
                    token: { uid: response.headers['uid'], client: response.headers['client'], access_token: response.headers['access-token']},
                    user: response.data.data
                }
                set({ token: result.token, user: result.user, isAuthenticated: true })
            } catch (error) {
                console.log(error)
                set({error: error.response.data.errors[0]})
            }
        },
        signOut: async () => {
            try {
                await http().delete('/auth/sign_out')
                set({ token: {}, user: {}, error: "", isAuthenticated: false })
            } catch (error) {
                console.log(error)
                set({error: error.response.data.errors[0]})
            }
        },
        resetData: (authReset = false) => {
            set({isAuthenticated: authReset ? get().isAuthenticated : false, error: "", user: {}, token: {}})
        }
    }),
    {
        name: "288ebcc61b", // unique name
        getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
))

export default useAuthStore;
