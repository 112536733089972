import React, {useEffect, useState} from "react";
import {Button, Card, Popconfirm, Space, Tooltip} from "antd";
import {CopyOutlined, DeleteOutlined, DollarOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import AppTable from "../../Generic/AppTable";
import EditModal from "../StockItems/EditModal";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";
import CostModal from "./CostModal";

const StockItem = () => {
    const FetchUrl = '/api/v1/stock_items'

    const { New, Edit, Remove } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const ModalVisible = useCrudStore(state => state.ModalVisible)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [AllKeys, SetAllKeys] = useState([])
    const [CostModalActive, SetCostModalActive] = useState(false)
    const [CostStockItemId, SetCostStockItemId] = useState(null)
    const [ReloadTable, SetReloadTable] = useState(false)
    const columns = [
        {
            title: 'Stok Kodu',
            dataIndex: 'stock_code',
            key: 'stock_code',
            searchKey: 'stock_code',
        },
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            searchKey: 'name'
        },
        {
            title: 'Varsayılan Birim',
            dataIndex: 'stock_unit_abbr',
            key: 'stock_unit_abbr',
            responsive: ['md'],
        },
        {
            title: 'Maliyet',
            dataIndex: 'cost_with_unit',
            key: 'cost_with_unit',
            responsive: ['md'],
        },
        {
            title: 'Kayıp Yüzdesi',
            dataIndex: 'outage_rate',
            key: 'outage_rate',
            responsive: ['md'],
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => Edit(record.key)}><EditOutlined/></a>
                    <Tooltip title="Maliyet Ekle">
                        <a onClick={() => {
                            SetCostStockItemId(record.key)
                            SetCostModalActive(true)
                        }}><DollarOutlined/></a>
                    </Tooltip>

                    <Popconfirm
                        title="Silmek istediğinize emin misiniz?"
                        onConfirm={() => {Remove(record.key)}}
                        okText="Sil"
                        cancelText="İptal"
                    >
                        <a href="#"><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>
            ),
        }
    ];


    const extraButtons = () => {
        return (
            <Space>
                <Button type='primary' onClick={New}><PlusOutlined/> Ekle</Button>
                <Popconfirm
                    title="Tüm ürünleri silmek istediğinize emin misiniz. Bu işlemin geri dönüşü yoktur ürünlerler birlikte reçete içerikleri de silinecektir?"
                    onConfirm={() => {Remove('all')}}
                    okText="Sil"
                    cancelText="İptal"
                >
                    <Button type='danger'><DeleteOutlined/>Tümünü Sil</Button>
                </Popconfirm>

                {
                    AllKeys.length > 0 ? (
                        <Popconfirm
                            title={`Seçili ${AllKeys.length} hammaddeyi silmek istediğinize emin misiniz. Bu işlemin geri dönüşü yoktur ürünlerler birlikte reçete içerikleri de silinecektir?`}
                            onConfirm={() => {Remove(AllKeys.join(','))}}
                            okText="Sil"
                            cancelText="İptal"
                        >
                            <Button type='warning'><DeleteOutlined/>Seçilileri Sil</Button>
                        </Popconfirm>
                    ) : null
                }
                {/* <Button type='danger' onClick={RemoveFilter}><ReloadOutlined/></Button> */}
            </Space>
        )
    }

    const onSelectChange = (item, selected) => {
        let arr = [...AllKeys]
        if (selected) {
            arr.push(item.key)
        }
        else {
            arr = arr.filter(x => x !== item.key)
        }
        SetAllKeys([...new Set(arr)])
    };

    const rowSelection = {
        AllKeys,
        preserveSelectedRowKeys: true,
        onSelect: onSelectChange,
    };

    return (
        <Card title="Hammaddeler" extra={extraButtons()}>
            <AppTable
                ReloadTable={ReloadTable}
                SetReloadTable={SetReloadTable}
                RowSelection={rowSelection}
                columns={columns} FetchUrl={FetchUrl}/>
            { ModalVisible ? <EditModal FetchUrl={FetchUrl} /> : null }
            { CostModalActive ? <CostModal ReloadTable={ReloadTable} SetReloadTable={SetReloadTable} CostStockItemId={CostStockItemId} CostModalActive={CostModalActive} SetCostModalActive={SetCostModalActive}/> : null }
        </Card>
    )
}

export default StockItem