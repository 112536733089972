import React, {useEffect, useState} from "react";
import {Button, Card, Popconfirm, Space, Tag} from "antd";
import {
    ArrowLeftOutlined,
    BackwardOutlined, CopyOutlined, DeleteOutlined,
    EditOutlined, EyeOutlined, PlusOutlined,
} from "@ant-design/icons";
import AppTable from "../../Generic/AppTable";
import EditModal from "./EditModal";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";
import {Link, useHistory, useParams} from "react-router-dom";
import http from "../../../http/http-common";
import useSubStore from "../../../store/SubStore";

const ProductionLists = () => {

    const FetchUrl = '/api/v1/production_lists'
    const { New, Edit, Clone, Remove } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const ModalVisible = useCrudStore(state => state.ModalVisible)

    const columns = [
        {
            title: 'Tarih',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Ürün Grubu',
            dataIndex: 'category_name',
            key: 'category_name'
        },
        {
            title: 'Kullanıcı',
            dataIndex: 'user_name',
            key: 'user_name'
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                if (!record.is_base) {
                    return (
                        <Space size="middle">
                            <a href={typeof category_id === "undefined" ? `/receteler/${record.key}` : `/recete-kategorileri/${category_id}/receteler/${record.key}`}><EyeOutlined/></a>
                            <a onClick={() => Edit(record.key)}><EditOutlined/></a>
                            <Popconfirm
                                title="Kopyalamak istediğinize emin misiniz?"
                                onConfirm={() => {Clone(record.key)}}
                                okText="Kopyala"
                                cancelText="İptal"
                            >
                                <a href="#"><CopyOutlined/></a>
                            </Popconfirm>
                            <Popconfirm
                                title="Silmek istediğinize emin misiniz?"
                                onConfirm={() => {Remove(record.key)}}
                                okText="Sil"
                                cancelText="İptal"
                            >
                                <a href="#"><DeleteOutlined/></a>
                            </Popconfirm>
                        </Space>
                    )
                }
            },
        }
    ];

    return (
        <Card title={
            <Space>
                <Button type={"primary"} onClick={useHistory().goBack} ><ArrowLeftOutlined/></Button>
                {`Üretim Listeleri`}
            </Space>
        } extra={<Button type='primary' onClick={New}><PlusOutlined/>Yeni Üretim</Button>}>
            <Card type={'inner'} title={"Üretim Listeleri"}>
                <AppTable TableKey={"Üretim Listeleri"} columns={columns} FetchUrl={FetchUrl}/>
            </Card>

            { ModalVisible ? <EditModal FetchUrl={FetchUrl} /> : null }
        </Card>
    )
}

export default ProductionLists