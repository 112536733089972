import React from "react";
import {Button, Result} from "antd";
import {useHistory} from "react-router-dom";

const NotFound = () => {
    let history = useHistory();

    return (
        <Result
            status="404"
            title="404"
            subTitle="Üzgünüz aradığınız sayfayı bulamadık."
            extra={<Button type="primary" onClick={() => {history.goBack()}}>Geri Dön</Button>}
        />
    )
}

export default NotFound