import React, {useEffect, useState} from "react";
import {Card, Col, Popconfirm, Row, Space, Statistic, Table, Tag} from "antd";
import http from "../../../http/http-common";
import {Link} from "react-router-dom";
import {CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";

const Dashboard = () => {

    const [Loading, SetLoading] = useState(true)
    const [Data, SetData] = useState([])

    useEffect(() => {
        http()
            .get('api/v1/dashboard')
            .then((data) => {
                SetData(data.data)
                SetLoading(false)
            })
    }, [])

    const columns = [
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            searchKey: 'name',
            responsive: ['md']
        },
        {
            title: 'Maliyet',
            dataIndex: 'cost_with_unit',
            key: 'cost_with_unit',
            responsive: ['md'],
            render: (text, record) => {
                if (record.final_product) {
                    return (
                        <Tag color={'green'}>
                            {text} (Nihai Ürün)
                        </Tag>
                    )
                } else {
                    return text
                }
            }
        },
        {
            title: 'Personel Gideri',
            dataIndex: 'labor_cost',
            key: 'labor_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Paketleme Gideri',
            dataIndex: 'packaging_cost',
            key: 'packaging_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Nakliye Gideri',
            dataIndex: 'transfer_cost',
            key: 'transfer_cost',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Diğer Giderler',
            dataIndex: 'other_costs',
            key: 'other_costs',
            responsive: ['md'],
            render: text => `${text} ₺`
        },
        {
            title: 'Zayi Oranı',
            dataIndex: 'outage_rate',
            key: 'outage_rate',
            responsive: ['md'],
            render: text => `% ${text}`
        }
    ];

    return (
        <Card title="Panel" loading={Loading}>
            <Row gutter={16}>
                <Col span={8}>
                    <Card type={"inner"}>
                        <Statistic title="Nihai Ürün Reçetesi" value={Data.main_recipe_count} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card type={"inner"}>
                        <Statistic title="Reçete" value={Data.all_recipe_count} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card type={"inner"}>
                        <Statistic title="Hammadde" value={Data.stock_item_count} />
                    </Card>
                </Col>

                <Col style={{paddingTop: 20}} title={"asd"} span={24}>
                    <Table dataSource={Data.recipes} loading={Loading} columns={columns} />
                </Col>
            </Row>
        </Card>
    )
}

export default Dashboard