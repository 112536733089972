import React, {useState} from "react";
import {Form, Input, Button, Checkbox, Layout, Card, Col, Row, Alert} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import useAuthStore from "../../../store/AuthStore";
import shallow from 'zustand/shallow'


const Login = () => {
    const {login, error} = useAuthStore(state => ({login: state.login, error: state.error}), shallow)
    const [btnLoading, setBtnLoading] = useState(false)

    // useStore.subscribe(() => {
    //     if (isAuthenticated)
    //         location.reload()
    // }, state => state.isAuthenticated)

    useAuthStore.subscribe(() => {
        setBtnLoading(false)
    }, state => state.error)

    const onFinish = values => {
        useAuthStore.setState({error: ""})
        setBtnLoading(true)
        login({email: values.email, password: values.password})
    };



    return (
        <Layout>
            <Row gutter={12} style={{height: '100vh'}}>
                <Col xs={{span: 20, offset: 2}} lg={{span: 12, offset: 6}}>
                    <Card style={{marginTop: 50}}>
                        { error ? <Alert message={error} type="error" style={{textAlign: 'center'}} /> : null }
                        <Form
                            style={{margin: '0 auto'}}
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                        >
                            <h2>Giriş Yap</h2>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'E-Mail adresi giriniz!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="E-Mail" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Parola giriniz!',
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Parola"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember_me" valuePropName="checked" noStyle>
                                    <Checkbox>Beni Hatırla</Checkbox>
                                </Form.Item>

                                <Link to={"/auth/forgot-password"}>Parolamı unuttum</Link>
                            </Form.Item>

                            <Form.Item>
                                <Button loading={btnLoading} type="primary" htmlType="submit" className="login-form-button">
                                    Giriş Yap
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default Login