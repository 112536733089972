import React, {useEffect, useRef} from "react";
import {Button, Form, Input, Modal, Cascader, Tabs, Select} from "antd";
import shallow from 'zustand/shallow'
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useSubStore from "../../../store/SubStore";
import http from "../../../http/http-common";
import {useParams} from "react-router-dom";

const RecipeItemModal = ({ FetchUrl }) => {
    let { id } = useParams();
    const [form] = Form.useForm()
    const { Update, Create, Cancel } = new StoreCrudHelper(FetchUrl, useSubStore)
    const [Item, Loading, ModalVisible, AdditionalData] = useSubStore(state => [state.Item, state.Loading, state.ModalVisible, state.AdditionalData], shallow)
    const IsEdit = Item.action === 'edit'
    const [stockItems, setStockItems] = React.useState([]);
    const [recipes, setRecipes] = React.useState([]);
    const [consumableType, setConsumableType] = React.useState(Item.action === "edit" ? Item.consumable_type : 'StockItem')
    const { TabPane } = Tabs
    const StockItemSelect = useRef()
    const RecipeSelect = useRef()
    const CurrentCategory = AdditionalData.stock_unit_options.find((item) => item.items.map(x => x.id).includes(Item.stock_unit_id))
    if (typeof CurrentCategory !== "undefined")
        Item.stock_unit_id = [CurrentCategory.id, Item.stock_unit_id]

    const Resolver = (array) => {
        return array.map((i) => {
            name = i?.name_with_category ? i.name_with_category : i.name_with_unit
            return { label: name, value: i.key}
        })
    }

    useEffect(() => {
        http()
            .get(`/api/v1/recipes/${id}/recipe_item_options`, {params: {form_type: Item.action}})
            .then((response) => {
                let { data } = response.data
                let stock_items = Resolver(data.stock_items)
                let recipes = Resolver(data.recipes)
                setStockItems(stock_items)
                setRecipes(recipes)
            })
    }, [])

    const Submit = () => {
        form.validateFields()
            .then(values => {
                let params = {...values}
                params.consumable_type = consumableType
                params.stock_unit_id = params.stock_unit_id[1]
                if (IsEdit)
                    Update(Item.key, {recipe_item: params}).then(() => location.reload())
                else
                    Create({recipe_item: params}).then(() => location.reload())
            })
            .catch(errors => {
                console.log(errors)
            })
    }

    const callback = (e) => {
        form.setFieldsValue({consumable_id: undefined})
        if (e === "tab_recipe")
            setConsumableType('Recipe')
        else
            setConsumableType('StockItem')
    }


    return (
        <Modal
            visible={ModalVisible}
            title={IsEdit ? "Düzenle" : "Yeni"}
            onOk={Submit}
            width={1000}
            onCancel={Cancel}
            footer={[
                <Button key="back" onClick={Cancel}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={Loading} onClick={Submit}>
                    { IsEdit ? "Güncelle" : "Yeni" }
                </Button>
            ]}
        >
            <Form
                layout="horizontal"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                form={form}
                initialValues={Item}
            >
                <Tabs defaultActiveKey={Item.action === "edit" ? (Item.consumable_type === "Recipe" ? "tab_recipe" : "tab_stock_item") : "tab_stock_item"} onChange={callback}>
                    <TabPane tab="Stoklu Ürün" key="tab_stock_item">
                        <Form.Item name="consumable_id" label={'Stoklu Ürün'} rules={[{required: true}]}>
                            <Select
                                // filterOption={false}
                                ref={StockItemSelect}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.label.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0
                                }
                                //onSearch={debounceFetcher}
                                showSearch={true}
                                options={stockItems}
                            />
                        </Form.Item>
                    </TabPane>
                    <TabPane tab="Reçete" key="tab_recipe">
                        <Form.Item name="consumable_id" label={'Reçete'} rules={[{required: true}]}>
                            <Select
                                // filterOption={false}
                                ref={RecipeSelect}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.label.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0
                                }
                                //onSearch={debounceFetcher}
                                showSearch={true}
                                options={recipes}
                            />
                        </Form.Item>
                    </TabPane>
                </Tabs>

                <Form.Item name="stock_unit_id" label={'Birim'} rules={[{required: true}]}>
                    <Cascader
                        options={AdditionalData.stock_unit_options}
                        fieldNames={{label: 'name', value: 'id', children: 'items'}}
                        placeholder={'Seçiniz'}
                        showSearch={{ filter: (inputValue, path) => path.some(option => option.name.toLocaleLowerCase('tr').indexOf(inputValue.toLocaleLowerCase('tr')) > -1) } }
                    />
                </Form.Item>

                <Form.Item name="amount" label={'Miktar'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="Miktar" />
                </Form.Item>

                <Form.Item name="outage_rate" label={'Zayi Oranı (%)'} rules={[{required: true}]}>
                    <Input autoComplete="off" placeholder="Zayi Oranı (%)" min={0} max={100} value={0} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default RecipeItemModal;