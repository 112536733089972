import React from "react";
import {Route, Switch} from "react-router-dom";
import PageLayout from "./Layout/PageLayout";
import Dashboard from "./Pages/Dashboard/Dashboard";
import StockUnit from "./Pages/StockUnits/Index";
import StockItem from "./Pages/StockItems/Index";
import RecipeCategories from "./Pages/RecipeCategories/Index";
import Recipes from "./Pages/Recipes/Index";
import RecipeDetail from "./Pages/Recipes/RecipeDetail";
import NotFound from "./Generic/NotFound";
import Users from "./Pages/Users/Index";
import Audits from "./Pages/Auidts/Audits";
import ProductionLists from "./Pages/ProductionLists/Index";


const App = () => {

    return (
        <PageLayout>
            <Switch>
                <Route exact path={"/"} component={Dashboard} />
                <Route exact path={"/birimler"} component={StockUnit} />
                <Route exact path={"/hammaddeler"} component={StockItem} />
                <Route exact path={"/recete-kategorileri"} component={RecipeCategories} />
                <Route exact path={"/recete-kategorileri/:category_id"} component={Recipes} />
                <Route exact path={"/recete-kategorileri/:category_id/receteler/:id"} component={RecipeDetail} />
                <Route exact path={"/receteler"} component={Recipes} />
                <Route exact path={"/receteler/:id"} component={RecipeDetail} />
                <Route exact path={"/kullanicilar"} component={Users} />
                <Route exact path={"/uretim-listesi"} component={ProductionLists} />
                <Route exact path={"/hareketler"} component={Audits} />
                <Route path={"*"} component={NotFound} />
            </Switch>
        </PageLayout>
    )
}

export default App;