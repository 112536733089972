import React, {useEffect, useState} from "react";
import {Button, Card, Popconfirm, Space} from "antd";
import {CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import AppTable from "../../Generic/AppTable";
import moment from "moment";
import useCrudStore from "../../../store/CrudStore";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";

const Audits = () => {
    const FetchUrl = '/api/v1/audits'

    const columns = [
        {
            title: 'Tarih',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => {
                return moment(record.created_at).format('DD-MM-YYYY HH:mm:ss')
            },
        },
        {
            title: 'İşlemi Yapan',
            dataIndex: 'user_name',
            key: (text, record) => 'user_name' + record.id,
        },
        {
            title: 'Etkilenen',
            dataIndex: 'audit_type',
            key: (text, record) => 'audit_type' + record.id,
        },
        {
            title: 'İşlem',
            dataIndex: 'audit_action',
            key: (text, record) => 'audit_action_' + record.id,
        },
        {
            title: 'Değerler',
            dataIndex: 'all_changes',
            key: (text, record) => 'all_changes_' + record.id,
            render: (text, record) => {
                try {
                    if (record.audit_action === "Giriş Yapıldı") {
                        return "-"
                    } else if (record.audit_action === "Güncellendi") {
                        return (
                            <table key={record.id + '_table_inner_table'}>
                                <tbody>{Object.keys(record.all_changes).map((key) => (
                                    <tr key={record.id + '_row_' + key}>
                                        <th>{key}:</th>
                                        <td>{record.all_changes[key][0]}=>{record.all_changes[key][1]}
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        )
                    } else {
                        return (
                            <table key={record.id + '_table'}>
                                <tbody>
                                {
                                    Object.keys(record.all_changes).map((key) => (
                                        <tr key={record.id + '_row_' + key}>
                                            <th>{key}:</th>
                                            <td>{record.all_changes[key]}</td>
                                        </tr>)
                                    )
                                }
                                </tbody>
                            </table>
                        )
                    }
                } catch {
                    return <span></span>
                }
            },
        },

    ];

    return (
        <Card title="Sistem Hareketleri">
            <AppTable rowKey={'id'} columns={columns} FetchUrl={FetchUrl} Store={useCrudStore}/>
        </Card>
    )
}

export default Audits