import React from "react";
import {Button, Card, Space} from "antd";
import {EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import AppTable from "../../Generic/AppTable";
import EditModal from "../RecipeCategories/EditModal";
import StoreCrudHelper from "../../../Utils/StoreCrudHelper";
import useCrudStore from "../../../store/CrudStore";
import {Link} from "react-router-dom";

const RecipeCategories = () => {
    const FetchUrl = '/api/v1/recipe_categories'

    const { New, Edit } = new StoreCrudHelper(FetchUrl, useCrudStore)
    const ModalVisible = useCrudStore(state => state.ModalVisible)

    const columns = [
        {
            title: 'Reçete Grubu',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a href={`/recete-kategorileri/${record.key}`}><EyeOutlined/></a>
                    <a onClick={() => Edit(record.key)}><EditOutlined/></a>
                </Space>
            ),
        }
    ];

    const extraButtons = () => {
        return (
            <Space>
                <Button type='primary' onClick={New}><PlusOutlined/></Button>
                {/* <Button type='danger' onClick={RemoveFilter}><ReloadOutlined/></Button> */}
            </Space>
        )
    }

    return (
        <Card title="Ürün Grupları" extra={extraButtons()}>
            <AppTable columns={columns} FetchUrl={FetchUrl}/>
            { ModalVisible ? <EditModal FetchUrl={FetchUrl} /> : null }
        </Card>
    )
}

export default RecipeCategories